module directives {
    export module applicationcore {
        interface IOptionalScope extends ng.IScope {
        }

        export class optionalDirective implements ng.IDirective {

            template = `&ensp;`;
            scope = {};

            constructor() {
            }

            link = ($scope: IOptionalScope, $element: ng.IAugmentedJQuery) => {

            }

            static factory(): ng.IDirectiveFactory {
                const directive = () => new optionalDirective();
                directive.$inject = [];

                return directive;
            }
        }

        angular.module("app").directive("opt", optionalDirective.factory());
    }
}